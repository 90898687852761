import { ClockIcon, EmployeesIcon, UserIcon, XIcon } from "~/utils/icons.js";

const ValueProp = ({ icon, title }: { icon: React.ReactNode; title: string }) => (
  <div
    className="flex flex-col gap-2 border-[0.5px] rounded-md p-4 items-start bg-surface lg:bg-transparent"
    style={{
      boxShadow: "0px 1px 1px 0px rgba(0, 0, 0, 0.12)",
    }}
  >
    <div className="text-blue-700 flex items-center justify-center">{icon}</div>
    <h3 className="text-sm font-medium">{title}</h3>
  </div>
);

const ValueProps = () => (
  <div className="grid grid-cols-2 gap-2 mt-4">
    <ValueProp icon={<EmployeesIcon />} title="Maximize talent density" />
    <ValueProp icon={<UserIcon />} title="Empower managers" />
    <ValueProp icon={<ClockIcon />} title="Real-time visibility" />
    <ValueProp icon={<XIcon />} title="Remove biases" />
  </div>
);

export default ValueProps;
