import { AnimatePresence, motion } from "framer-motion";
import type { ReactNode } from "react";

import { Spinner } from "@wind/ui";

interface Props {
  isLoading: boolean;
  children: ReactNode;
}

const SubmitButton = ({ isLoading, children }: Props) => {
  return (
    <button
      type="submit"
      disabled={isLoading}
      className="relative rounded-full py-1 px-4 text-white font-medium bg-gradient-to-b from-[#70B2F4] to-[#4084FB] hover:from-[#60A2E4] hover:to-[#3074ED] transition-all duration-200 text-nowrap flex-none items-center justify-center flex"
      style={{
        boxShadow: "0px 4px 8px 0px rgba(255, 255, 255, 0.25) inset",
      }}
    >
      <motion.div
        animate={{
          opacity: isLoading ? 0 : 1,
        }}
        className="flex-grow text-center text-sm lg:text-medium"
      >
        {children}
      </motion.div>
      <AnimatePresence>
        {isLoading && (
          <motion.div
            initial={{ opacity: 0, width: 0, translateY: "0", translateX: "-50%" }}
            animate={{ opacity: 1, width: "auto", translateY: "-50%", translateX: "-50%" }}
            exit={{ opacity: 0, width: 0, translateY: "0", translateX: "-50%" }}
            className="absolute left-1/2 top-1/2"
          >
            <Spinner className="p-0" />
          </motion.div>
        )}
      </AnimatePresence>
    </button>
  );
};

export default SubmitButton;
