import WindmillWordmark from "~/components/marketing/WindmillWordmark.js";

const CardHeader = () => (
  <div className="flex flex-col justify-start gap-2">
    <WindmillWordmark className="w-36" />
    <div className="mt-12">
      <span className="text-sm px-2 py-0.5 font-medium rounded-full font-serif marketing-accent-chip">
        Coming soon
      </span>
    </div>
    <h1 className="text-4xl font-medium mt-1 font-serif text-balance leading-[3rem]">
      AI Superpowers for Managers
    </h1>
    <h3 className="text-secondary">Take the chores out of management</h3>
  </div>
);

export default CardHeader;
